import Header from './components/Header'
import Home from './components/Home'

function App() {
  return (
    <div >

      <Header />


      <Home />


    </div>
  );
}

export default App;
